import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetPlansRepoType, IGetPlansRepo } from '../repos';
import { IPlan } from '../interfaces';

export const usePlans = () => {
  const getPlansRepo = useService<IGetPlansRepo>(GetPlansRepoType);

  return useQuery<IPlan[]>(
    'plans',
    async () => {
      const result = await getPlansRepo.execute({ page: 1, per_page: 10000 });

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    {
      initialData: [],
    },
  );
};
