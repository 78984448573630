import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetShopsRepoType, IGetShopsRepo } from '../repos';
import { IShop } from '../interfaces';

export const useShops = () => {
  const getShopsRepo = useService<IGetShopsRepo>(GetShopsRepoType);

  return useQuery<IShop[], Error, IShop[]>(
    'shops',
    async () => {
      const result = await getShopsRepo.execute({ page: 1, per_page: 10000 });

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    {
      initialData: [],
    },
  );
};
