import React, { useContext, useMemo, useState } from 'react';
import { message, Modal, Upload } from 'antd';
import * as Icons from '@ant-design/icons';
import { UploadProps } from 'antd/lib/upload';

import { useService } from '@core/inversify-react';
import { HeaderButton } from '@modules/layout';

import { IUploadCustomsDeclarationsDocumentRepo, UploadCustomsDeclarationsDocumentRepoType } from '../repos';
import { CustomsDeclarationsTableContext } from '../context';

export const UploadCustomsDeclarationsDocument = () => {
  const { fetch } = useContext(CustomsDeclarationsTableContext);
  const uploadCustomsDeclarationsDocumentRepo = useService<IUploadCustomsDeclarationsDocumentRepo>(UploadCustomsDeclarationsDocumentRepoType);
  const [loading, setLoading] = useState(false);

  const props = useMemo<UploadProps>(
    () => ({
      fileList: [],
      accept: 'application/json',
      beforeUpload: () => false,
      onChange: async (event) => {
        setLoading(true);

        if (event.file.originFileObj) {
          const result = await uploadCustomsDeclarationsDocumentRepo.execute(event.file.originFileObj);

          if (result.status === 200) {
            fetch();
            message.success('Sənəd müvəffəqiyyətlə yükləndi.');
            Modal.info({
              content: (
                <div>
                  <p>Sənəd müvəffəqiyyətlə yükləndi.</p>
                  <p>
                    <strong>Bəyan edilmiş: {result.response.counts.declared}</strong>
                  </p>
                  <p>
                    <strong>Bəyan edilməmiş: {result.response.counts.undeclared}</strong>
                  </p>
                  <p>
                    <strong>Bilinməyən: {result.response.counts.nonExistUsers}</strong>
                  </p>
                </div>
              ),
              okText: 'Bağla',
            });
          } else {
            message.error(result.response);
          }
        }

        setLoading(false);
      },
    }),
    [fetch, uploadCustomsDeclarationsDocumentRepo],
  );

  return (
    <Upload {...props}>
      <HeaderButton loading={loading} icon={<Icons.UploadOutlined />}>
        Sənəd yüklə
      </HeaderButton>
    </Upload>
  );
};
