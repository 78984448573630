import { useCallback } from 'react';
import * as React from 'react';
import { useHistory, generatePath, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu, message, Modal, Select, Tag } from 'antd';
import { Column } from 'react-table';
import { useQuery } from 'react-query';
import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { useService } from '@core/inversify-react';
import { CheckboxFilter, CheckCell, OverCell, tableColumn, tableColumns, TableContext, TagCell } from '@core/table';
import { filterOption } from '@core/form';
import { useBus } from '@core/event-bus';

import { useStatusByModelId } from '@modules/status';
import { usePlanTypes } from '@modules/plans';
import { useProductTypes } from '@modules/product-types';
import { UserCell } from '@modules/users';
import { useAuth } from '@modules/auth';
import { useTinyBoxes } from '@modules/boxes';
import { useBranches } from '@modules/branches';
import { useParcels } from '@modules/parcels';
import { useTinyFlights } from '@modules/flights';

import {
  declarationKeys,
  DeclarationStatusTag,
  GetDeclarationOrderUrlRepoType,
  IDeclaration,
  IGetDeclarationOrderIdsRepo,
  IRemoveDeclarationsFromFlightRepo,
  IRemoveDeclarationsRepo,
  IUpdateDeclarationsReadRepo,
  RemoveDeclarationsFromFlightRepoType,
  RemoveDeclarationsRepoType,
  UpdateDeclarationsReadRepoType,
  usePrintProformaInvoice,
  usePrintWaybill,
  useUpdateDeclarationsStatus,
} from '@modules/declarations';

export const useFlightDeclarationTableColumns = (context: React.Context<TableContext>) => {
  const history = useHistory();
  const location = useLocation();
  const { can } = useAuth();
  const eventBus = useBus();
  const { state } = React.useContext(context);
  const status = useStatusByModelId('2');
  const planTypes = usePlanTypes();
  const productTypes = useProductTypes();
  const boxes = useTinyBoxes();
  const branches = useBranches();
  const flights = useTinyFlights();

  const flightId = React.useMemo(() => {
    if (flights.data) {
      const flightFilter = state.filters.find((f) => f.id === 'flight_name');
      if (flightFilter) {
        return flights.data?.find((f) => f.name === flightFilter.value)?.id;
      }
    }
  }, [state.filters, flights.data]);

  const parcels = useParcels({ flight_id: flightId });

  const printProformaInvoiceById = usePrintProformaInvoice();
  const printWaybillById = usePrintWaybill();
  const updateStatusFactory = useUpdateDeclarationsStatus();
  const removeDeclarationFromFlightRepo = useService<IRemoveDeclarationsFromFlightRepo>(RemoveDeclarationsFromFlightRepoType);
  const removeDeclarationRepo = useService<IRemoveDeclarationsRepo>(RemoveDeclarationsRepoType);
  const getDeclarationOrderIdsRepo = useService<IGetDeclarationOrderIdsRepo>(GetDeclarationOrderUrlRepoType);
  const updateDeclarationsReadRepo = useService<IUpdateDeclarationsReadRepo>(UpdateDeclarationsReadRepoType);

  const actionsColumn = React.useMemo<Column<IDeclaration>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = React.useState<boolean>(false);

        const waybillIsDisabled = React.useMemo(() => !(original.status.id === 7 || original.status.id === 8), [original.status.id]);

        const openEdit = React.useCallback(() => {
          history.push(generatePath('/@next/declarations/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const openDetails = React.useCallback(() => {
          history.push(generatePath('/@next/declarations/:id', { id: original.id }));
        }, [original.id]);

        const openDocument = React.useCallback(() => {
          window.open(original.document || '', '_blank');
        }, [original.document]);

        const openTimeline = React.useCallback(() => {
          history.push(generatePath('/@next/declarations/:id/timeline', { id: original.id }), { background: location });
        }, [original.id]);

        const printWaybill = React.useCallback(() => {
          return printWaybillById({ declarationId: original.id });
        }, [original.id]);

        const printProformaInvoice = React.useCallback(() => {
          return printProformaInvoiceById({ declarationIds: [original.id] });
        }, [original.id]);

        const handover = React.useCallback(() => {
          history.push(generatePath('/@next/declarations/:id/handover', { id: original.id }), { background: location });
        }, [original.id]);

        const returnDec = React.useCallback(() => {
          history.push(generatePath('/@next/declarations/:id/return', { id: original.id }), { background: location });
        }, [original.id]);

        const remove = React.useCallback(() => {
          const onOk = async () => {
            const result = await removeDeclarationRepo.execute([original.id]);

            if (result.status === 200) {
              eventBus.publish({ type: '@declarations/remove/succeed', payload: { id: original.id } });
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: 'Bağlamanı silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const removeFromFlight = React.useCallback(() => {
          const onOk = async () => {
            const result = await removeDeclarationFromFlightRepo.execute([original.id]);

            if (result.status === 200) {
              eventBus.publish({ type: '@declarations/remove-from-flight/succeed', payload: { id: original.id } });
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: 'Bağlamanı uçuşdan silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const toggleRead = React.useCallback(async () => {
          const result = await updateDeclarationsReadRepo.execute({ ids: [original.id], read: original.read });

          if (result.status === 200) {
            eventBus.publish({ type: '@declarations/toggleRead/succeed', payload: { id: original.id } });
          } else {
            message.error(result.response);
          }
        }, [original.id, original.read]);

        const updateStatus = React.useCallback(
          (statusId: string | number) => {
            return updateStatusFactory({ declarationIds: [original.id], statusId });
          },
          [original.id],
        );

        const openOrder = useCallback((id: string | number) => {
          history.push({ pathname: generatePath('/@next/orders/:id/info', { id }) });
        }, []);

        const orderIds = useQuery<string[], Error, string[], string[]>(
          ['declarations', original.id.toString(), 'orders', 'id'],
          async ({ queryKey }) => {
            const [, id] = queryKey;

            const result = await getDeclarationOrderIdsRepo.execute({ id });

            if (result.status === 200) {
              return result.response;
            } else {
              throw new Error(result.response);
            }
          },
          {
            enabled: visible,
            initialData: [],
          },
        );

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item onClick={openDetails} icon={<Icons.FileSearchOutlined />}>
              Ətraflı bax
            </Menu.Item>
            <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={removeFromFlight} icon={<Icons.DeleteOutlined />}>
              Uçuşdan çıxar
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={handover} disabled={original.status.id !== 9 || !can('declarations_handover')} icon={<Icons.CheckCircleOutlined />}>
              Təhvil ver
            </Menu.Item>
            <Menu.Item onClick={returnDec} icon={<Icons.RollbackOutlined />}>
              İadə et
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={openDocument} disabled={!original.document} icon={<Icons.FileOutlined />}>
              Sənəd
            </Menu.Item>
            <Menu.SubMenu title='Çap et' icon={<Icons.PrinterOutlined />}>
              <Menu.Item onClick={printProformaInvoice}>Proforma</Menu.Item>
              <Menu.Item onClick={printWaybill} disabled={waybillIsDisabled}>
                Yol vərəqəsi
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Divider />
            <Menu.SubMenu title='Sifarişlər' disabled={!orderIds.data?.length} icon={<Icons.ShoppingCartOutlined />}>
              {orderIds.data?.map((id, index) => (
                <Menu.Item key={index} onClick={() => openOrder(id)}>
                  Sifariş #{index}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
            <Menu.Divider />
            <Menu.Item onClick={openTimeline} icon={<Icons.FieldTimeOutlined />}>
              Status xəritəsi
            </Menu.Item>
            <Menu.Divider />
            <Menu.SubMenu title='Digər' icon={<Icons.BorderInnerOutlined />}>
              <Menu.Item onClick={toggleRead} icon={<Icons.BookOutlined />}>
                {original.read ? 'Oxunmamış et' : 'Oxunmuş et'}
              </Menu.Item>
              <Menu.SubMenu title='Status dəyiş' icon={<Icons.AppstoreOutlined />}>
                <Menu.Item onClick={() => updateStatus(9)}>Yerli anbarda</Menu.Item>
                <Menu.Item onClick={() => updateStatus(36)}>Gömrükdə saxlanılıb</Menu.Item>
              </Menu.SubMenu>
            </Menu.SubMenu>
            <Menu.Item onClick={remove} disabled={!can('declaration_cancel')} icon={<Icons.DeleteOutlined />}>
              Sil
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [
      can,
      history,
      location,
      printWaybillById,
      printProformaInvoiceById,
      removeDeclarationFromFlightRepo,
      removeDeclarationRepo,
      eventBus,
      updateDeclarationsReadRepo,
      updateStatusFactory,
      getDeclarationOrderIdsRepo,
    ],
  );

  const baseColumns = React.useMemo<Column<IDeclaration>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.user.id,
        id: declarationKeys.user.id,
        Header: 'M. kodu',
      },
      {
        accessor: (row) => row.user.name,
        id: declarationKeys.user.name,
        Header: 'Müştəri',
        Cell: ({ row: { original } }) => <UserCell id={original.user.id} title={original.user.name} />,
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.trackCode,
        id: declarationKeys.trackCode,
        Header: 'İzləmə kodu',
        Cell: ({ cell: { value }, row: { original } }) => <Tag color={!original.read ? 'green' : 'default'}>{value}</Tag>,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.globalTrackCode,
        id: declarationKeys.globalTrackCode,
        Header: 'Q.İ kodu',
        Cell: TagCell,
      },
      {
        ...tableColumn(122, 'px'),
        accessor: (row) => row.status?.name,
        id: declarationKeys.status.id,
        Header: 'Status',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {status.data?.map((status) => (
                <Select.Option key={status.id} value={status.id.toString()}>
                  {status.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Cell: ({ row: { original } }) => <DeclarationStatusTag id={original.status.id} name={original.status.name} />,
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.paid,
        id: declarationKeys.paid,
        Header: 'Ödəniş',
        Cell: CheckCell,
        Filter: CheckboxFilter,
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.approved,
        id: declarationKeys.approved,
        Header: 'Bəyan',
        Cell: CheckCell,
        Filter: CheckboxFilter,
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.returned,
        id: declarationKeys.returned,
        Header: 'İadə',
        Cell: CheckCell,
        Filter: CheckboxFilter,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.flight?.name,
        id: declarationKeys.flightName,
        Header: 'Uçuş',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select showSearch={true} filterOption={filterOption} allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {flights.data?.map((flight) => (
                <Select.Option key={flight.id} value={flight.name}>
                  {flight.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Cell: OverCell,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.weight,
        id: declarationKeys.weight,
        Header: 'Çəki',
        Cell: ({ cell: { value } }) => !!value && `${value.toFixed(2)} kq`,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.price,
        id: declarationKeys.price,
        Header: 'Məhsulun qiyməti',
        Cell: ({ cell: { value }, row: { original } }) => !!value && `${value.toFixed(2)} ${original.countryId === 1 ? '₺' : '$'}`,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.deliveryPrice,
        id: declarationKeys.deliveryPrice,
        Header: 'Çatdırılma qiyməti',
        Cell: ({ cell: { value } }) => !!value && `${value.toFixed(2)} $`,
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.quantity,
        id: declarationKeys.quantity,
        Header: 'Say',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.type,
        id: declarationKeys.type,
        Header: 'Tərkibi',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              <Select.Option value='1'>Maye</Select.Option>
              <Select.Option value='2'>Digər</Select.Option>
            </Select>
          );
        },
        Cell: ({ cell: { value } }) => (value === 'liquid' ? 'Maye' : 'Digər'),
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.planCategory.name,
        id: declarationKeys.planCategory.name,
        Header: 'Tarif',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {planTypes.data?.map((planType) => (
                <Select.Option key={planType.id} value={planType.id.toString()}>
                  {planType.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Cell: OverCell,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.shop,
        id: declarationKeys.shop.name,
        Header: 'Mağaza',
        Cell: OverCell,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.productType.name,
        id: declarationKeys.productType.id,
        Header: 'Məhsulun tipi',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select showSearch={true} filterOption={filterOption} allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {productTypes.data?.map((productType) => (
                <Select.Option key={productType.id} value={productType.id.toString()}>
                  {productType.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Cell: OverCell,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.parcel?.id,
        id: declarationKeys.parcelId,
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select showSearch={true} filterOption={filterOption} allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {parcels.data?.map((parcel) => (
                <Select.Option key={parcel.id} value={parcel.id.toString()}>
                  {parcel.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Header: 'Koli',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.box?.name,
        id: declarationKeys.box.id,
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select showSearch={true} filterOption={filterOption} allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {boxes.data?.map((box) => (
                <Select.Option key={box.id} value={box.id.toString()}>
                  {box.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Header: 'Yeşik',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.branch?.name,
        id: declarationKeys.branch.id,
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select showSearch={true} filterOption={filterOption} allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {branches.data?.map((branches) => (
                <Select.Option key={branches.id} value={branches.id.toString()}>
                  {branches.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Header: 'Filial',
        Cell: OverCell,
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: declarationKeys.createdAt,
        Header: 'Yaradılma tarixi',
      },
    ],
    [boxes.data, branches.data, flights.data, parcels.data, planTypes.data, productTypes.data, status.data],
  );

  return React.useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};
